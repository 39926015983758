// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { styled } from "@mui/material/styles";
import {
  DataSuiteHero,
  DataSuiteFeatures,
  DataSuiteExplainerPanels,
  DataSuiteUseCasesCarousel,
} from "./sections";
import { LandingRequestDemoPanel } from "blocks/views/LandingPage/foundations";
import { ProofPoints, LandingFooter } from "blocks/views/LandingPage/features";
import Page from "blocks/views/Layouts/Page";
import { MotionContainer } from "blocks/atoms/uieffects/animate";

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(11),
  },
}));

export default function BoardSuite() {
  return (
    <Page title="AI Dealrooms @ Trryst">
      <MotionContainer>
        <RootStyle>
          <DataSuiteHero />
          <DataSuiteFeatures />
          <DataSuiteUseCasesCarousel />
          <DataSuiteExplainerPanels />

          {/*   <AboutVision />
          <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 2, height: 40 }} />
          <AboutTeam />
  <AboutTestimonials />*/}
          <LandingRequestDemoPanel />
          <ProofPoints baseColor="white" />
          <LandingFooter />
        </RootStyle>
      </MotionContainer>
    </Page>
  );
}
