// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Stack,
  Card,
  Button,
  Container,
  Typography,
  Zoom,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  Divider,
  Fade,
  useMediaQuery,
} from "@mui/material";
import {
  Forward,
  ArrowCircleLeft,
  ArrowCircleRight,
} from "@mui/icons-material";
// hooks
import { Carousel } from "react-responsive-carousel";
import useResponsive from "hooks/useResponsive";
import {
  MotionViewport,
  varFade,
  SignupButton,
  MotionContainer,
} from "blocks/atoms";
import Image from "next/image";
// components
import {
  FlowBox,
  StyledHorizontalTabs,
  StyledHorizontalTab,
} from "blocks/atoms/uistyles";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
  backgroundColor: alpha(theme.palette.primary.lighter, 0.15),
}));

// ----------------------------------------------------------------------
const usecasesList = [
  {
    id: 0,
    name: "Due Diligence",
    blurb: "Maintain Data Security and transaction confidentiality",
    image: "/static/images/DueDiligence.png",
  },
  {
    id: 1,
    name: "RFP Management",
    blurb: "Bring all your RFP stakeholders together",
    image: "/static/images/rfp-background.png",
  },
  {
    id: 2,
    name: "M&A Rooms",
    blurb: "Share data and communicate with complete confidence",
    image: "/static/images/Mergeracquisition_background.png",
  },
  {
    id: 3,
    name: "Real Estate Deals",
    blurb: "Virtual data rooms to convert your deals faster ",
    image: "/static/images/LegalContractManagement_background.png",
  },
];

export default function DataSuiteUseCasesCarousel() {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = React.useState(
    theme.direction === "rtl" ? usecasesList.length - 1 : 0
  );

  const isDesktop = useResponsive("up", "md");

  const isLight = theme.palette.mode === "light";
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  let carouselRef = useRef(null);

  // const settings = {
  //   arrows: false,
  //   infinite: true,
  //   speed: 2500,
  //   autoplay: true,
  //   autoplaySpeed: 7000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   centerMode: true,
  //   focusOnSelect: true,
  //   centerPadding: "0 px",
  //   cssEase: "ease-in-out",
  //   rtl: Boolean(theme.direction === "rtl"),
  //   afterChange: (newIndex) => {
  //     console.log("newIndex", newIndex);
  //     setCurrentIndex(newIndex);
  //   },
  //   // nextArrow: <CarouselNextArrow />,
  //   // prevArrow: <CarouselPrevArrow />,

  //   responsive: [
  //     {
  //       breakpoint: 1279,
  //       settings: { slidesToShow: 1 },
  //     },
  //     {
  //       breakpoint: 959,
  //       settings: { slidesToShow: 1 },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: { slidesToShow: 1 },
  //     },
  //   ],
  // };

  const carouselSettings = {
    autoFocus: true,
    autoPlay: true,
    centerMode: true,
    centerSlidePercentage: 100,
    dynamicHeight: false,
    emulateTouch: true,
    infiniteLoop: true,
    interval: 3000,
    onChange: (index, item) => setCurrentIndex(index),
    selectedItem: currentIndex,
    showStatus: false,
    showIndicators: true,
    showThumbs: false,
    transitionTime: 2000,
    animationHandler: "fade",
  };
  const isLessThanMediumSize = useMediaQuery(theme.breakpoints.down("md"));

  const UsecaseSituationsList = () => {
    if (isLessThanMediumSize) {
      return (
        <FlowBox sx={{ maxWidth: "95vw" }}>
          <StyledHorizontalTabs
            value={currentIndex}
            onChange={(event, index) => setCurrentIndex(index)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            TabIndicatorProps={{
              sx: {
                minHeight: 10,
              },
            }}
          >
            {usecasesList.map((usecase, index) => {
              const isActive = index === currentIndex;
              return <StyledHorizontalTab key={index} label={usecase.name} />;
            })}
          </StyledHorizontalTabs>
        </FlowBox>
      );
    } else
      return (
        <List sx={{ maxWidth: 280 }}>
          {usecasesList.map((usecase, index) => {
            const isActive = index === currentIndex;
            return (
              <React.Fragment key={index}>
                <ListItem dense>
                  <ListItemButton
                    onClick={() => setCurrentIndex(index)}
                    sx={{ mr: 0, pr: 0, my: 0 }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="h4"
                          color={isActive ? "primary" : "standard"}
                        >
                          {usecase.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  {isActive ? (
                    <ListItemSecondaryAction sx={{ mt: 1 }}>
                      <Forward color="secondary" />
                    </ListItemSecondaryAction>
                  ) : (
                    <Box sx={{ width: 30 }} />
                  )}
                </ListItem>
                <Fade in={isActive}>
                  <Divider
                    sx={{
                      mx: 5,
                      mb: 3,
                      borderBottomWidth: 5,
                      borderColor: "secondary.main",
                    }}
                    color="secondary"
                    variant="inset"
                    component="li"
                  />
                </Fade>
              </React.Fragment>
            );
          })}
        </List>
      );
  };

  const UsecaseDetailsCarousel = () => {
    return (
      <FlowBox sx={{ position: "relative" }}>
        {/* <Box component={motion.div} sx={{ height: 100 }}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {featuresList?.find((feature) => feature.id === currentIndex)
                .blurb ?? " "}
            </Typography>
          </Box> */}
        <Carousel autoPlay ref={carouselRef} {...carouselSettings}>
          {usecasesList.map((feature) => (
            <FeatureCard
              isLessThanMediumSize={isLessThanMediumSize}
              key={feature.id}
              feature={feature}
              isActive={feature.id === currentIndex}
            />
          ))}
        </Carousel>
      </FlowBox>
    );
  };

  return (
    <MotionContainer>
      <RootStyle>
        <Box
          component={MotionViewport}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <motion.div>
                <Typography
                  variant="h2"
                  sx={{ mt: 5, color: "primary.main", textAlign: "center" }}
                >
                  Advanced Collaboration Solutions for any of your Dataroom
                  needs
                </Typography>
              </motion.div>
            </Box>

            <Grid
              container
              sx={{ my: 6 }}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={3.5}
                sx={{
                  maxWidth: "95vw",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <UsecaseSituationsList />
              </Grid>
              <Grid
                item
                xs={12}
                md={8.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pt: 5,
                  flexGrow: 1,
                  width: "100%",
                  height: "100%",

                  maxWidth: "95vw",
                }}
              >
                <UsecaseDetailsCarousel />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <SignupButton />
            </Box>
          </Container>
        </Box>
      </RootStyle>
    </MotionContainer>
  );
}

// ----------------------------------------------------------------------

FeatureCard.propTypes = {
  isLessThanMediumSize: PropTypes.bool,
  isActive: PropTypes.bool,
  feature: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    blurb: PropTypes.string,
  }),
};

function FeatureCard({ feature, isActive, isLessThanMediumSize = false }) {
  const { name, blurb, image } = feature;

  return (
    <Box
      sx={{
        height: isLessThanMediumSize ? "30vh" : "50vh",
        //transform: `scale(${isActive ? 1.5 : 1})`,
        filter: `grayscale(${isActive ? "10%" : "100%"})`,
        transition: "transform 2s, filter 2s",
        flexGrow: 1,
        position: "relative !important",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" color="primary.main">
        {blurb}
      </Typography>

      <Image
        alt={name}
        src={image}
        sizes={`(max-width: 768px) 80vw, (max-width: 1200px) 55vw, 55vw`}
        fill
        style={{ objectFit: "contain", borderRadius: 10 }}
      />
    </Box>
  );
}
