// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
import { m, motion } from "framer-motion";
// @mui
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Fab,
  styled,
} from "@mui/material";
// components
import { SignupButton, varFade } from "blocks/atoms";
import { alpha, useTheme } from "@mui/material/styles";
import { useHandleRouterPush } from "@app21/core";
import { useIsMobileBreakpoint } from "hooks";
import Image from "next/image";
import ShowLandingVideo from "blocks/views/LandingPage/features/ShowLandingVideo";
import TrrystDataSuiteLandingHeroPattern from "public/static/images/TrrystDataSuiteLandingHeroPattern.svg";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",

  // backgroundImage: `linear-gradient(to right, ${
  //   theme.palette.grey[900]
  // } 0%,${alpha(theme.palette.grey[700], 0.8)} 50%, ${alpha(
  //   theme.palette.grey[300],
  //   0.5
  // )} 100%), url(/static/images/boardsplashhero.jpg)`,
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
  /* Curved corners */
  borderBottomLeftRadius: "50% 15%",
  borderBottomRightRadius: "50% 15%",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(15),
  },
}));

// ----------------------------------------------------------------------

export default function DataSuiteHero() {
  const { loadRoute } = useHandleRouterPush();
  const isMobile = useIsMobileBreakpoint();
  return (
    <RootStyle sx={{ position: "relative" }}>
      <Image
        src={TrrystDataSuiteLandingHeroPattern}
        alt={""}
        width={isMobile ? 50 : 100}
        height={isMobile ? 50 : 100}
        sizes={"100vw"}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          objectFit: "cover",
        }}
      />
      <Container
        maxWidth="md"
        sx={{ position: "relative", height: "100%", py: isMobile ? 2 : 10 }}
      >
        <Grid
          container
          spacing={2}
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                alignSelf: "flex-end",

                mt: isMobile ? 3 : 8,
                p: 2,
              }}
            >
              <Typography variant={isMobile ? "h3" : "h1"}>
                Intelligent Dealrooms with Trryst Data Suites
              </Typography>
              <Typography variant={isMobile ? "subtitle2" : "subtitle1"}>
                <List dense>
                  <ListItem disableGutters>
                    AI-powered Meeting rooms for impactful interactions
                  </ListItem>
                  <ListItem disableGutters>
                    Streamlined and gated communications
                  </ListItem>
                  <ListItem disableGutters>
                    Maximum deal security and privacy
                  </ListItem>
                  <ListItem disableGutters>
                    Document Usage Analytics, usage protections and Insights
                  </ListItem>
                </List>
                <motion.div animate="animate" variants={varFade().inRight}>
                  {/* <Button
                    variant="contained"
                    size="large"
                    sx={{ my: 4 }}
                    color="primary"
                    onClick={() =>
                      loadRoute("GOTO-LINK", {
                        hrefLink: "/home/contactus?ref=demo",
                      })
                    }
                  >
                    SIGN UP
                  </Button> */}
                  <SignupButton />
                </motion.div>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              minHeight: "30vh",
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ShowLandingVideo ratio={0.9} />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
