import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import Image from "next/image";
import TrrystDataSuiteLandingPageFeaturesBackground from "public/static/images/TrrystDataSuiteLandingPageFeaturesBackground.svg";
import { FlowBox } from "blocks/atoms/uistyles";
const featuresInfo = [
  {
    imageSrc: "/static/customicons/storage_white.svg",
    hoveredImageSrc: "/static/customicons/storage_fullcolour.svg",
    title: "Integrated Collaboration",
    description:
      "The only Dataroom platform in the market offering Integrated Collaboration features - Videocall, Chat, Filesharing, Analytics and AI. All in one easy-to-use platform",
  },
  {
    imageSrc: "/static/customicons/security_white.svg",
    hoveredImageSrc: "/static/customicons/security_fullcolour.svg",
    title: "Secure Rooms",
    description:
      "Leading Industry security standards for your sensitive information. Ensure that the files you share are only received and seen by the intended recipients and no one else",
  },
  {
    imageSrc: "/static/customicons/finger_white.svg",
    hoveredImageSrc: "/static/customicons/finger_fullcolour.svg",
    title: "Easy to Manage",
    description:
      "Trryst shines in how easy it is to setup and use. No elaborate training manuals and complex technical integrations needed. Get your secure dataroom up and running in less than 5 minutes.",
  },
];

const FeatureBox = ({ feature }) => {
  const {
    imageSrc = "",
    hoveredImageSrc = "",
    title,
    description,
  } = feature ?? {};
  const [hovered, setHovered] = React.useState(false);

  return (
    <FlowBox
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        p: 4,
        bgcolor: hovered ? "secondary.main" : "primary.main",
        borderRadius: (theme) => theme.shape.borderRadius,
      }}
    >
      <Image
        src={hovered ? hoveredImageSrc : imageSrc}
        alt={title}
        width={50}
        height={50}
      />
      <Typography
        variant="h3"
        sx={{
          mt: 10,
          mb: 3,
          color: hovered ? "primary.main" : "primary.contrastText",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: hovered ? "primary.contrastText" : "scondary.contrastText",
        }}
      >
        {description}
      </Typography>
    </FlowBox>
  );
};

export default function DataSuiteFeatures({}) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundImage: `url(${TrrystDataSuiteLandingPageFeaturesBackground})`,
        backgroundSize: "cover",

        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="md"
        sx={{ zIndex: 2, my: 10, p: 3, color: "primary.contrastText" }}
      >
        <Grid container direction={{ xs: "column" }}>
          <Grid item xs={12} md={5}>
            <Typography variant="h1" sx={{ my: 5, textAlign: "center" }}>
              AI-based Lifecycle Management platform for your deals
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="subtitle1">
              Data Suite is your virtual space with all collaboration features
              integrated to enable your teams to operate at peak performance.
              From securely sharing documents to tracking usage Analytics,
              Videocall meetings to Post-meeting tasks management and and more.
              Built-in AI assistant helps you put together meeting minutes,
              generate usage insights and helps you retrieve information with
              ease
            </Typography>
          </Grid>
          <Grid item container xs={12} sx={{ mt: 5 }}>
            {featuresInfo?.map((feature, index) => (
              <Grid key={index} item xs={12} md={4} sx={{ p: 3 }}>
                <FeatureBox feature={feature} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
