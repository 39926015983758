import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import Image from "next/image";
import { RenderBulletedHeadlineList } from "blocks/atoms";
//create a component with two panels using mui

export default function DataSuiteExplainerPanels() {
  const explainerItems = [
    {
      image: "/static/images/TranscriptScreenshot.png",
      title: "AI-enabled data rooms",
      blurb: "Achieve better dataroom outcomes with AI",
      description:
        "With Trryst AI-Augmented Data suites, you can videocall, chat, manage documents, schedule events, track tasks, transcribe your meetings even get AI-generated summaries, all in one place! Our platform makes it easy for you to connect with your dataroom participants and partners, no matter where you are. ",
      bulletedItems: [
        "Searchable recordings and AI-transcribed meetings",
        "Easily circulate meeting minutes via AI summarization built on the powerful OpenAI GPT3 platform",
        "Unique Searchable recordings feature. Search all your meetings with simple keywords using our powerful search feature ",
      ],
    },
    {
      image: "/static/images/files_documents_image.svg",
      title: "Best In class Data Security",
      blurb:
        "Secure document sharing with file protections and viewer analytics",
      description:
        "Selectively share documents and files with your dataroom participants leveraging our breakthrough fine-grained security model. Trryst document room is not just a file storage area. You also get a breakthrough visibility of key document usage analytics coupled with pdf document protections. ",
      bulletedItems: [
        "Get key document analytics such as most active users, document read times, download statistics and timestamps, top documents accessed etc",
        "File protections available for pdf documents such as watermarking and download protections etc ",
        "Chat and exchange comments on documents ",
      ],
    },
    {
      image: "/static/images/confidential_conversation_image.svg",
      title: "Confidential conversations in your dealroom",
      blurb: "Confidential conversations between dealroom participants",
      description:
        "Not just a dealroom. Bring communications , information and analytics together. Our unique breakouts model ensures that you can silo your conversations between different participants. ",
      bulletedItems: [
        "Breakouts model provides a multi-layered security model. Silo conversations between different participants",
        "Members of one breakout cannot access resources (or know about the other breakout participants). Helps you conduct multi-vendor conversations within the dataroom while ensuring secrecy and confidentiality",
        "Integrated chat model. Establish a dynamic chat group for any resource within the dealroom - whether a videocall, assigned task, document or a team poll",
      ],
    },
  ];

  return (
    <Container maxWidth="md" sx={{ py: 6, my: 10 }}>
      <Grid container direction="column">
        {explainerItems.map((explainerItem, index) => (
          <Grid
            item
            key={index}
            container
            direction={{
              xs: "column",
              sm: index % 2 === 0 ? "row-reverse" : "row",
            }}
            sx={{
              py: 5,
              px: 3,
              my: 10,
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minHeight: 200,
              }}
            >
              <Image
                alt={explainerItem.blurb}
                src={explainerItem.image}
                sizes={`(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 45vw`}
                fill
                style={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ minHeight: 300, px: 5 }}>
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                {explainerItem.blurb}
              </Typography>
              <Typography variant="body1" sx={{ my: 3 }}>
                {explainerItem.description}
              </Typography>
              <RenderBulletedHeadlineList
                listItems={explainerItem.bulletedItems}
                ListItemProps={{
                  dense: true,
                  sx: { color: "text.primary", my: 0 },
                }}
                ListItemTypographyProps={{ variant: "subtitle1" }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
